import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './Home.scss';

function Home() {
  return (
    <div className="App">
      <section className='container-fluid' id='hero'>
        <div className='row'>
          <div className='col-6 d-flex align-items-center px-5'>
            <div>
              <h1>Elevate Your Style with <span>Outfino</span></h1>
              <p>Discover Fashion Designed for You.</p>
            </div>
          </div>
          <div className='col-6 d-flex justify-content-center align-items-center'>
            <div className='video'>
              <video autoPlay loop controls={false} muted>
                  <source src='https://api.trophien.com/files/shared/Projektek/Stylevise/outfino-commercial.mp4' type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </section>

      <section className='container-fluid' id='camera'>
        <div className='row'>
          <div className='col-6 d-flex align-items-center'>
            <div className='ms-3'>
              <h2>Scan your friend anywhere, anytime</h2>
            </div>
          </div>
          <div className='col-6'>
            
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
