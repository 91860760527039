class Data {
    static url = "https://api.outfino.io";

    static userData = {};

    static language = navigator.language.split('-')[0];

    static async fetchValidation(token) {
        const response = await fetch(`${Data.url}/registration/${token}/validate`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "lang": this.language
            }
        });

        if (!response.ok)
            throw new Error();

        return;
    }

    static async fetchResetPassword(token, password) {
        const response = await fetch(`${Data.url}/reset-password`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "lang": this.language,
                "token": token
            },
            body: JSON.stringify({
                "password": password
            })
        });

        if (response.status === 404)
            throw new Error("Request is expired!");
        
        if (!response.ok)
            throw new Error();

        return;
    }
}
export default Data;