import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import './App.scss';
import Validate from './components/Validate';
import ResetPassword from './components/ResetPassword';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/validate" element={<Validate />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
